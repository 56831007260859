import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import axios from 'axios';
import MobileSearchBar from '../MobileSearchBar';
import { connect } from 'react-redux';
import Check from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

const useStyles = makeStyles(() => ({
  secondary: {
    color: '#fff',
    backgroundColor: '#c8b37c',
  },
}));

function UserSearch(props) {
  const classes = useStyles();
  const [query, setQuery] = useState(null);
  const [userWrappers, setUserWrappers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [allSelectedUsers, setAllSelectedUsers] = useState([]);

  useEffect(() => {
    updateResults();
  }, [query]);

  const updateResults = () => {
    if (query) {
      axios.get(`${config.apiEndPoint}/users/search`, {
        params: {
          q: query,
          limit: 30,
        },
      }).then((response) => {
        console.log(response.data);
        setUserWrappers(response.data.map((user) => ({
          user,
        })));
      }).catch((err) => {
        console.log(err);
      });
    } else {
      axios.get(`${config.apiEndPoint}/users/${props.auth.user.id}/friends`, {
        params: {
          page: 1,
          limit: 30,
        },
      }).then((response) => {
        setUserWrappers(response.data.map((user) => ({
          user,
        })));
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const handleSelection = (user) => {
    const { multiSelect, onSelect } = props;
    if (multiSelect) {
      const isSelected = selectedUsers[user.id];
      const selections = selectedUsers;
      selections[user.id] = !isSelected;
      setSelectedUsers({ ...selections });
      if (!isSelected) {
        const users = [...allSelectedUsers, user];
        setAllSelectedUsers(users);
        onSelect(users);
      } else {
        const users = allSelectedUsers;
        let i = users.length;
        while (i--) {
          if (users[i].id === user.id) {
            users.splice(i, 1);
          }
        }
        setAllSelectedUsers(users);
      }
    } else {
      onSelect(user);
    }
  };

  return (
    <div>
      <MobileSearchBar
        placeholder="Search users…"
        onQueryChange={(newQuery) => {
          if (newQuery !== query) {
            setQuery(newQuery);
          }
        }} // TODO Done button when multiSelect
      />
      <List style={{ ...props.listStyle }}>
        {userWrappers.map((userWrapper) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => handleSelection(userWrapper.user)}
          >
            <ListItemAvatar>
              {selectedUsers[userWrapper.user.id] && <Avatar className={classes.secondary}><Check /></Avatar>}
              {!selectedUsers[userWrapper.user.id] && <Avatar alt={userWrapper.user.full_name} src={userWrapper.user.image_url} />}
            </ListItemAvatar>
            <ListItemText
              primary={<span style={{ fontSize: '1.2rem' }}>{userWrapper.user.full_name}</span>}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

UserSearch.propTypes = {
  listStyle: PropTypes.object,
};

UserSearch.defaultProps = {
  listStyle: {},
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(UserSearch);
