import React from 'react';
import './style.scss';

const UrlPreview = (props) => {
  console.log('Meta props:');
  console.log(props);
  const isGif = props.ogImage && props.ogImage.url.indexOf('giphy') !== -1;
  const getDomain = (url) => {
    try {
      const _url = new URL(url);
      return _url.hostname;
    } catch (e) {
      console.error(e);
      return url;
    }
  };
  if (props.compact) {
    return (
      <a href={!isGif ? props.requestUrl : '#'}>
        <div className="og-container compact">
          {/* FIXME Do we have to add Giphy's logo somewhere? */}
          {isGif && props.ogImage && props.ogImage.url ? <div className="og-image-wrapper" style={{ backgroundImage: `url(${props.ogImage.url}` }} /> : null}
          {!isGif && props.ogImage && props.ogImage.url ? <div className="og-image-wrapper" style={{ backgroundImage: `url(${props.ogImage.url}` }} /> : null}
          <div className="og-info">
            {!isGif && <span className="og-site-name" style={{ marginTop: 5 }}>{props.ogUrl || props.requestUrl}</span>}
            {!isGif && <span className="og-title">{props.ogSiteName || props.ogTitle}</span>}
            {!isGif && <span className="og-description">{props.ogDescription.substring(0, 50)}</span>}
          </div>
        </div>
      </a>
    );
  }
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (typeof props.onClick === 'function') {
          props.onClick(props.requestUrl);
        }
        window.open(props.requestUrl, '_blank');
      }}
    >
      <div className="og-container">
        {(props.ogImage && props.ogImage.url && !props.hideImage) ? <div className="og-image-wrapper" style={{ backgroundImage: `url(${props.ogImage.url}` }} /> : null}
        <div className="og-info">
          <span className="og-site-name">{getDomain(props.ogUrl || props.requestUrl)}</span>
          <span className="og-title">{props.ogSiteName || props.ogTitle}</span>
          <span className="og-description">{props.ogDescription}</span>
        </div>
      </div>
    </div>
  );
};

export default UrlPreview;
