/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import classnames from 'classnames/dedupe';
import { Nav, NavItem, NavLink } from 'reactstrap';

// FIXME This example has this working - https://stackblitz.com/edit/react-router-dom-4-nested-example
// FIXME And here - https://tylermcginnis.com/react-router-nested-routes/
import { initTabsSliding } from '../../../common-assets/js/rootui-parts/initTabsSliding';

window.RootUI.initTabsSliding = initTabsSliding;
window.RootUI.initTabsSliding();

// https://tylermcginnis.com/react-router-nested-routes/
class RoutedTabs extends Component {
  componentDidMount() {
    window.jQuery(window).trigger('rui-ajax-loaded');
  }

  render() {
    const {
      sliding = false,
      pills = false,
      children,
      className,
    } = this.props;

    return (
      <Nav className={classnames(pills ? 'nav-pills' : 'nav-tabs', sliding ? 'rui-tabs-sliding' : '', className)}>
        { children }
      </Nav>
    );
  }
}

class TabsNavItem extends Component {
  render() {
    const {
      children,
      className,
      to
    } = this.props;

    const isActive = window.location.pathname === to;

    return (
      <NavItem>
        <Link
          className={classnames({ active: isActive }, 'rui-tabs-link nav-link', className)}
          onClick={() => this.props.history.push(to)} // FIXME Why's the link not working by default?
          to={to}
        >
          { children }
        </Link>
      </NavItem>
    );
  }
}

class TabContent extends Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        { children }
      </div>
    );
  }
}

class TabPane extends Component {
  render() {
    const { path, children } = this.props;
    return (
      <Route exact key={path} path={path} render={() => children} />
    );
  }
}

RoutedTabs.NavItem = withRouter(TabsNavItem);
RoutedTabs.Content = TabContent;
RoutedTabs.Pane = TabPane;

export default RoutedTabs;
